import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./src/assets/locales/en/translation.json";
import translationFR from "./src/assets/locales/fr/translation.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      "en-US": {
        translation: translationEN,
      },
      "fr-FR": {
        translation: translationFR,
      },
    },
    fallbackLng: "en-US",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
