export default {
  skills: [
    {
      name: "Photoshop",
      bgColor: "#007E61",
      borderColor: "#47C5A8",
      fontColor: "#FFFFFF",
      link: "#",
      img: "/icons/photoshop.svg",
    },
    {
      name: "Indesign",
      bgColor: "#FFFFFF",
      borderColor: "#EACF68",
      fontColor: "#000000",
      link: "#",
      img: "/icons/indesign.svg",
    },
    {
      name: "After effect",
      bgColor: "#F4C200",
      borderColor: "#FFE375",
      fontColor: "#000000",
      link: "#",
      img: "/icons/aftereffect.svg",
    },
    {
      name: "Illustrator",
      bgColor: "#16192C",
      borderColor: "#515FB7",
      fontColor: "#FFFFFF",
      link: "#",
      img: "/icons/illustrator.svg",
    },
    {
      name: "Figma",
      bgColor: "#007E61",
      borderColor: "#47C5A8",
      fontColor: "#FFFFFF",
      link: "#",
      img: "/icons/figma.svg",
    },
  ],
  projects: [
    {
      name: "Because i can't love you",
      type: {
        "fr-FR": "Logo",
        "en-US": "Logo",
      },
      img: "/projects/bicly.svg",
      link: "https://www.behance.net/gallery/163151723/LOGO-REDESIGN-BITLY",
    },
    {
      name: "BDS",
      type: {
        "fr-FR": "Concept de StreamPack",
        "en-US": "StreamPack concept",
      },
      img: "/projects/bds.svg",
      link: "https://www.behance.net/gallery/150419249/BDS-CONCEPT",
    },
    {
      name: "NcommeNico",
      type: {
        "fr-FR": "Identitée visuelle",
        "en-US": "Visual identity",
      },
      img: "/projects/ncn.svg",
      link: "https://www.behance.net/gallery/155453811/NcommeNico-Streampack",
    },
    {
      name: "Recto Verso",
      type: {
        "fr-FR": "Web Design",
        "en-US": "Web Design",
      },
      img: "/projects/rectov.svg",
      link: "https://www.behance.net/gallery/170302267/Web-design-Concept",
    },
    {
      name: "Maison dada",
      type: {
        "fr-FR": "Affiche / Illustration",
        "en-US": "Poster / Illustration",
      },
      img: "/projects/maisondada.svg",
      link: "https://www.behance.net/gallery/182152255/Maison-Dada-Projet-Stage-n1",
    },
    {
      name: "Les six pilliers de la coopération",
      type: {
        "fr-FR": "Schéma de livre édition duno",
        "en-US": "Book scheme duno edition",
      },
      img: "/projects/livre.svg",
      link: "https://www.behance.net/gallery/181981927/Schma-Livre-les-six-piliers-de-la-coopration",
    },
    {
      name: "Discord Apex France",
      type: {
        "fr-FR": "Identitée visuelle",
        "en-US": "Visual identity",
      },
      img: "/projects/apexfr.svg",
      link: "https://www.behance.net/gallery/183408495/Identit-visuelle-Apex-France",
    },
    {
      name: "The velvet underground & Nico",
      type: {
        "fr-FR": "Projet scolaire",
        "en-US": "School project",
      },
      img: "/projects/tvun.svg",
      link: "https://www.behance.net/gallery/182279137/The-velvet-underground-Nico",
    },
    {
      name: "Campus Mana",
      type: {
        "fr-FR": "Projet Stage",
        "en-US": "internship project",
      },
      img: "/projects/manaa.svg",
      link: "https://www.behance.net/gallery/184809403/MANA-STAGE-MONTAGE-VIDEO",
    },
  ],
  socials: [
    {
      name: "Behance",
      img: "/socials/behance.svg",
      link: "https://www.behance.net/bthomas",
    },
    {
      name: "LinkedIn",
      img: "/socials/in.svg",
      link: "https://www.linkedin.com/in/bouyeron-thomas-9b344a255/",
    },
    {
      name: "Discord",
      img: "/socials/discord.svg",
      link: "https://discord.com/users/791780560300933120",
    },
    {
      name: "Mail",
      img: "/socials/mail.svg",
      link: "mailto:thomasbouyeron@studio16.art",
    },
  ],
};
