import { create } from "zustand";

export const useThemeStore = create((set) => ({
  theme: {
    img: "/backgrounds/bg1.svg",
    fontPrimaryColor: "#FFFFFF",
    fontSecondaryColor: "#F4C200",
    fontHoverColor: "#F4C200",
  },
  setTheme: (theme) => set({ theme }),
}));
