import { useTranslation } from "react-i18next";
import { useState } from "react";
import { motion } from "framer-motion";

import { useThemeStore } from "../../store/themeStore";

import NavbarItem from "./NavbarItem";

const navbarVariants = {
  hidden: {
    opacity: 0,
    y: -300,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      delay: 1.1,
      staggerChildren: 0.1,
      when: "beforeChildren",
      bounce: 0.5,
    },
  },
};

const navbarItemVariants = {
  hidden: {
    opacity: 0,
    y: -300,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

export default function Navbar() {
  const { theme } = useThemeStore();
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation("translation", {
    keyPrefix: "navbar",
  });
  const [currentLanguage, setCurrentLanguage] = useState(language);

  const changeLanguageHandler = () => {
    const newLanguage = language === "en-US" ? "fr-FR" : "en-US";
    setCurrentLanguage(newLanguage);
    changeLanguage(newLanguage);
  };

  const navbarItems = [
    {
      name: t("about"),
      path: "about",
    },
    {
      name: t("creations"),
      path: "creations",
    },
    {
      name: t("projects"),
      path: "projects",
    },
    {
      name: t("contact"),
      path: "contact",
    },
  ];

  return (
    <>
      <motion.div
        className="z-10 absolute top-[70px] justify-center w-full hidden flex-row gap-12 items-center px-6 md:px-0 sm:flex"
        variants={navbarVariants}
        initial="hidden"
        animate="visible"
      >
        {navbarItems.map((navbarItem, index) => (
          <NavbarItem
            key={index}
            name={navbarItem.name}
            path={navbarItem.path}
          />
        ))}
        <motion.div
          variants={navbarItemVariants}
          className="text-base font-semibold"
          style={{ color: theme.fontPrimaryColor }}
        >
          -
        </motion.div>
        <motion.div
          variants={navbarItemVariants}
          className="text-base font-semibold hover:!text-thomasYellow hover:drop-shadow-md cursor-pointer"
          style={{ color: theme.fontPrimaryColor }}
          onClick={changeLanguageHandler}
        >
          {currentLanguage === "en-US" ? "Français" : "English"}
        </motion.div>
      </motion.div>
    </>
  );
}
