import { motion } from "framer-motion";

import { useThemeStore } from "../../store/themeStore";
import { t } from "i18next";

export default function HeroBackground({ children, id }) {
  const { theme, setTheme } = useThemeStore();

  const heroBackgroundColors = [
    {
      img: "/backgrounds/bg2.svg",
      color: "#007E61",
      fontPrimaryColor: "#FFFFFF",
      fontSecondaryColor: "#F4C200",
      fontHoverColor: "#F4C200",
    },
    {
      img: "/backgrounds/bg3.svg",
      color: "#FFFFFF",
      fontPrimaryColor: "#000000",
      fontSecondaryColor: "#007E61",
      fontHoverColor: "#007E61",
    },
    {
      img: "/backgrounds/bg4.svg",
      color: "#00454A",
      fontPrimaryColor: "#FFFFFF",
      fontSecondaryColor: "#F4C200",
      fontHoverColor: "#F4C200",
    },
    {
      img: "/backgrounds/bg5.svg",
      color: "#F4C200",
      fontPrimaryColor: "#000000",
      fontSecondaryColor: "#007E61",
      fontHoverColor: "#007E61",
    },
  ];

  return (
    <>
      <div
        className="relative flex items-center justify-center w-full min-h-screen"
        id={id}
      >
        <div className="z-[-1] absolute top-0 left-0 w-full h-full">
          <img
            src={theme.img}
            alt="background"
            className="object-cover w-full h-full"
          />
        </div>
        <div className="absolute w-full h-[5px] bottom-0 left-0 flex flex-row gap-5 md:w-[5px] md:h-full md:flex-col">
          {heroBackgroundColors.map((heroBackgroundColor, index) => (
            <motion.div
              whileHover={{ width: "15px" }}
              key={index}
              className="h-full flex-1 cursor-pointer md:w-full md:h-auto"
              style={{ backgroundColor: heroBackgroundColor.color }}
              onClick={() => setTheme(heroBackgroundColor)}
            ></motion.div>
          ))}
        </div>
        {children}
      </div>
    </>
  );
}
