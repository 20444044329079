import { motion, AnimatePresence } from "framer-motion";
import { useEffect } from "react";

export default function Alert({ onDismiss, message, type, duration }) {
  const typeClass = () => {
    switch (type) {
      case "success":
        return "bg-green-500";
      case "danger":
        return "bg-red-500";
      case "warning":
        return "bg-yellow-500";
      default:
        return "bg-green-500";
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      onDismiss();
    }, duration);
    return () => clearTimeout(timer);
  }, [duration, onDismiss]);

  return (
    <motion.div
      className="fixed  z-50 bottom-0 left-0 w-full flex flex-col justify-center items-center"
      initial={{ translateY: 100, opacity: 0 }}
      animate={{ translateY: 0, opacity: 1 }}
      exit={{ translateY: 100, opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className={`p-4 w-full ${typeClass()}`}>
        <p className="text-white text-center text-sm">{message}</p>
      </div>
      <div className="w-full h-1 bg-white/10">
        <AnimatePresence>
          <motion.div
            className={`h-full ${typeClass()}`}
            initial={{ width: 0 }}
            animate={{ width: "100%" }}
            exit={{ width: 0 }}
            transition={{ duration: duration / 1000 }}
          ></motion.div>
        </AnimatePresence>
      </div>
    </motion.div>
  );
}
