import { useThemeStore } from "../../store/themeStore";

import HeroBackground from "../Home/HeroBackground";

export default function NotFoundPage() {
  const { theme } = useThemeStore();

  return (
    <HeroBackground>
      <div className="flex flex-col gap-2 items-center justify-center">
        <h1
          className="text-4xl font-bold text-center"
          style={{ color: theme.fontPrimaryColor }}
        >
          404 - Page Not Found
        </h1>
        <p
          className="text-xl text-center"
          style={{ color: theme.fontSecondaryColor }}
        >
          The page you are looking for does not exist.
        </p>
      </div>
    </HeroBackground>
  );
}
