import { motion } from "framer-motion";

import { useThemeStore } from "../../store/themeStore";

const navbarItemVariants = {
  hidden: {
    opacity: 0,
    y: -300,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

export default function NavbarItem({ name, path }) {
  const { theme } = useThemeStore();

  const handleClick = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <motion.div variants={navbarItemVariants}>
      <div onClick={() => handleClick(path)}>
        <motion.p
          className="text-base font-semibold hover:!text-thomasYellow cursor-pointer hover:drop-shadow"
          style={{ color: theme.fontPrimaryColor }}
        >
          {name}
        </motion.p>
      </div>
    </motion.div>
  );
}
