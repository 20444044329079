import CardArrowEffect from "../UI/CardArrowEffect";

export default function CreationCard({ title, desc, borderColor, img, link }) {
  return (
    <a
      className="group z-0 relative w-full overflow-hidden h-56 border p-6 hover:rounded-xl transition-all duration-300 cursor-pointer"
      style={{ borderColor: borderColor }}
      href={link}
      target="_blank"
    >
      <div className="z-[-1] absolute top-0 left-0 w-full h-full">
        <img
          src={img}
          alt="background"
          className="object-cover w-full h-full pointer-events-none brightness-50"
        />
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col gap-1 text-white">
          <h4 className="text-sm font-semibold">{title} -</h4>
          <p className="text-xs">{desc}</p>
        </div>
        <CardArrowEffect />
      </div>
    </a>
  );
}
