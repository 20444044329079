export default function AboutButton({ onClick, title, borderColor }) {
  return (
    <div
      className="bg-[#333648] min-w-max px-5 py-3 text-center border flex-1 cursor-pointer"
      style={{ borderColor: borderColor }}
      onClick={onClick}
    >
      {title}
    </div>
  );
}
