export default function Logo({ color }) {
  return (
    <svg
      // width="358"
      // height="275"
      viewBox="0 0 358 275"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-full"
    >
      <path
        d="M300.41 116.001V113.924C300.41 113.924 300.398 113.877 300.375 113.819C300.386 113.678 300.41 113.396 300.41 113.396C300.386 113.396 300.375 113.396 300.363 113.373L300.457 101.99C300.457 52.4676 270.142 0 210.804 0H110.171L110.206 0.0821456H53.8595V0.0234702H0L16.3303 49.1583H70.4597L105.948 159.574H268.359V159.668C291.025 159.668 309.408 170.663 309.408 193.336C309.408 216.008 291.025 226.229 268.359 226.229V226.252L127.792 226.053L141.67 275L268.359 274.836C317.866 274.836 358 242.869 358 193.336C358 155.114 334.091 126.891 300.41 116.001ZM153.519 111.049L133.974 48.5246H210.804V48.6185C233.481 48.6185 251.853 78.0383 251.853 100.722V110.85L252.451 111.049H153.519Z"
        fill={color}
      />
    </svg>
  );
}
