import { motion } from "framer-motion";

export default function ScrollIcon({ theme }) {
  return (
    <svg
      width="25"
      height="37"
      viewBox="0 0 25 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M12 9L12.0205 14.3166L12.04 19.38"
        stroke={theme.fontSecondaryColor}
        animate={{ y: [0, 8, 0] }}
        transition={{ duration: 3, repeat: Infinity }}
      />
      <rect
        x="0.5"
        y="0.5"
        width="23.08"
        height="35.12"
        rx="11.54"
        stroke={theme.fontPrimaryColor}
      />
    </svg>
  );
}
