import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Preloader from "./components/Layout/Preloader";
import NotFoundPage from "./components/Layout/NotFound";

import HomePage from "./pages/Home";

function App() {
  return (
    <>
      <Router>
        <Preloader />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
