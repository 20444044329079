export default function ColorBars() {
  return (
    <div className="absolute w-full h-[5px] bottom-0 left-0 flex flex-row gap-5">
      <div className="h-full flex-1 bg-thomasYellow"></div>
      <div className="h-full flex-1 bg-[#00454A]"></div>
      <div className="h-full flex-1 bg-white"></div>
      <div className="h-full flex-1 bg-[#007E61]"></div>
    </div>
  );
}
