import { motion } from "framer-motion";

export default function CategoryInfos({ title, desc, theme }) {
  return (
    <motion.div
      className="flex flex-col items-start gap-3"
      style={{ color: theme === "dark" ? "#fff" : "#000" }}
    >
      <h3 className="text-2xl font-semibold">{title}</h3>
      <p className="text-sm font-semibold">{desc}</p>
    </motion.div>
  );
}
