import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import Alert from "../UI/Alert";

export default function ContactForm({ variants }) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = (data) => {
    // TODO: Send data to backend
    // reset();
    setIsSubmitted(true);
  };

  return (
    <>
      <motion.form
        className="my-32 max-w-[1200px] p-5 flex flex-col rounded gap-4 mx-6 xl:mx-auto bg-white/50 border border-thomasLightGreen"
        onSubmit={handleSubmit(onSubmit)}
        variants={variants}
      >
        <div className="flex flex-col gap-1">
          <h4 className="text-thomasSapinGreen">{t("contact.title")}</h4>
          <p className="text-thomasLightGreen">{t("contact.description")}</p>
        </div>
        <div className="flex flex-col gap-1">
          <input
            type="text"
            placeholder={t("contact.form.fullname")}
            className="p-3 bg-white rounded text-sm text-thomasLightGreen border border-thomasLightGreen outline-none placeholder-thomasExtraLightGreen"
            {...register("name", {
              required: t("contact.form.errors.required"),
              minLength: {
                value: 2,
                message: t("contact.form.errors.minlength", { number: 3 }),
              },
              maxLength: {
                value: 30,
                message: t("contact.form.errors.maxlength", { number: 30 }),
              },
            })}
          />
          <AnimatePresence>
            {errors.name && (
              <motion.span
                className="text-red-400 text-xs"
                initial={{ translateY: -10, opacity: 0 }}
                animate={{ translateY: 0, opacity: 1 }}
                exit={{ translateY: -10, opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                {errors.name.message}
              </motion.span>
            )}
          </AnimatePresence>
        </div>
        <div className="flex flex-col gap-1">
          <input
            type="email"
            placeholder={t("contact.form.mail")}
            className="p-3 bg-white rounded text-sm text-thomasLightGreen border border-thomasLightGreen outline-none placeholder-thomasExtraLightGreen"
            {...register("email", {
              required: t("contact.form.errors.required"),
              pattern: {
                value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                message: t("contact.form.errors.email"),
              },
            })}
          />
          <AnimatePresence>
            {errors.email && (
              <motion.span
                className="text-red-400 text-xs"
                initial={{ translateY: -10, opacity: 0 }}
                animate={{ translateY: 0, opacity: 1 }}
                exit={{ translateY: -10, opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                {errors.email.message}
              </motion.span>
            )}
          </AnimatePresence>
        </div>
        <div className="flex flex-col gap-1">
          <textarea
            placeholder={t("contact.form.message")}
            className="p-3 bg-white rounded text-sm text-thomasLightGreen border border-thomasLightGreen outline-none placeholder-thomasExtraLightGreen resize-none min-h-[150px]"
            {...register("message", {
              required: t("contact.form.errors.required"),
              minLength: {
                value: 10,
                message: t("contact.form.errors.minlength", { number: 10 }),
              },
              maxLength: {
                value: 1000,
                message: t("contact.form.errors.maxlength", { number: 1000 }),
              },
            })}
          />
          <AnimatePresence>
            {errors.message && (
              <motion.span
                className="text-red-400 text-xs"
                initial={{ translateY: -10, opacity: 0 }}
                animate={{ translateY: 0, opacity: 1 }}
                exit={{ translateY: -10, opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                {errors.message.message}
              </motion.span>
            )}
          </AnimatePresence>
        </div>
        <button
          type="submit"
          className="w-max py-3 px-20 rounded text-sm text-thomasSapinGreen border border-thomasLightGreen outline-none placeholder-thomasExtraLightGreen hover:bg-thomasLightGreen/50 hover:text-white transition-all disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={isSubmitted}
        >
          {t("contact.form.send")}
        </button>
      </motion.form>
      <AnimatePresence>
        {isSubmitted && (
          <Alert
            type="danger"
            message={t("contact.form.notAvailable")}
            duration={3000}
            onDismiss={() => setIsSubmitted(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
}
