import { motion, useInView } from "framer-motion";
import { useRef } from "react";

export default function Section({ children, id, className, ...props }) {
  const sectionRef = useRef(null);
  const inView = useInView(sectionRef, {
    once: true,
    margin: "0px 0px -400px 0px",
  });

  return (
    <motion.section
      ref={sectionRef}
      id={id}
      className={className}
      {...props}
      variants={{
        hidden: {
          opacity: 1,
        },
        visible: {
          opacity: 1,
          transition: {
            duration: 0.3,
            delay: 0.4,
            staggerChildren: 0.1,
            when: "beforeChildren",
          },
        },
      }}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
    >
      {children}
    </motion.section>
  );
}
