import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import Logo from "../UI/Logo";

const preloaderVariants = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
  visible: {
    opacity: 1,
  },
};

export default function Preloader() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1100);
  }, []);

  useEffect(() => {
    console.log("This website is created by Sybrax (https://sybrax.dev/).");
  }, []);

  return (
    <AnimatePresence>
      {isLoading && (
        <motion.div
          className={`fixed top-0 left-0 w-full h-screen flex flex-row justify-center items-center z-50 bg-thomasBlueGray`}
          variants={preloaderVariants}
          initial="visible"
          exit="hidden"
        >
          <motion.div
            className="h-16"
            initial={{
              opacity: 0,
              scale: 0,
            }}
            animate={{
              opacity: 1,
              scale: 2,
              transition: {
                duration: 1.1,
              },
            }}
          >
            <Logo color="#FFFFFF" />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
