import ColorBars from "../UI/ColorBars";
import CardArrowEffect from "../UI/CardArrowEffect";

export default function ProjectCard({ name, type, img, link }) {
  return (
    <a
      className="group z-0 relative w-full h-44 p-6 cursor-pointer bg-thomasBlueGray"
      href={link}
      target="_blank"
    >
      <div className="flex flex-row justify-between">
        <div className="flex flex-col gap-1 text-white">
          <h4 className="text-sm font-semibold">{name} -</h4>
          <p className="text-xs">Class : {type}</p>
        </div>
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <img
            src={img}
            alt={name + "-img"}
            className="max-h-[50%] max-w-[60%] pointer-events-none"
          />
        </div>
        <CardArrowEffect />
        <ColorBars />
      </div>
    </a>
  );
}
