import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import MenuItem from "./MenuItem";

const variants = {
  open: {
    x: 0,
    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.2,
      x: { duration: 0 },
    },
  },
  closed: {
    x: "-100%",
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
      x: { duration: 0, delay: 0.5 },
    },
  },
};

const changeLanguageVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      delay: 0.5,
    },
  },
  closed: {
    opacity: 0,
    y: 50,
    transition: {
      duration: 0.3,
    },
  },
};

export default function Navigation({ toggle }) {
  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation("translation", {
    keyPrefix: "navbar",
  });
  const [currentLanguage, setCurrentLanguage] = useState(language);

  const links = [
    { name: t("home"), path: "home" },
    { name: t("about"), path: "about" },
    { name: t("creations"), path: "creations" },
    { name: t("projects"), path: "projects" },
    { name: t("contact"), path: "contact" },
  ];

  const handleLanguageChange = () => {
    const newLanguage = language === "en-US" ? "fr-FR" : "en-US";
    changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
  };
  return (
    <motion.ul
      variants={variants}
      className="z-30 fixed text-center top-0 left-0 flex flex-col w-full h-full justify-center items-center gap-2 p-4"
    >
      {links.map((link) => (
        <MenuItem link={link} toggle={toggle} key={link.name} />
      ))}
      <div className="fixed bottom-0 left-0 w-full p-4">
        <motion.button
          onClick={handleLanguageChange}
          className="p-4 bg-[#222430] w-full rounded-md text-white font-bold"
          variants={changeLanguageVariants}
        >
          {currentLanguage === "en-US" ? "Français" : "Anglais"}
        </motion.button>
      </div>
    </motion.ul>
  );
}
