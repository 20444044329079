import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import { useThemeStore } from "../store/themeStore";

import config from "../config";

import Navbar from "../components/Layout/Navbar";
import MobileNavbar from "../components/Layout/MobileNavbar";
import HeroBackground from "../components/Home/HeroBackground";
import Section from "../components/Layout/Section";

import Badge from "../components/UI/Badge";
import CategoryInfos from "../components/UI/CategoryInfos";
import ColorBars from "../components/UI/ColorBars";
import GradientTransition from "../components/UI/GradientTransition";

import AboutButton from "../components/About/AboutButton";
import AboutContent from "../components/About/AboutContent";
import CreationCard from "../components/Creations/CreationCard";
import ProjectCard from "../components/Projects/ProjectCard";
import ContactForm from "../components/Contact/ContactForm";

import ScrollIcon from "../components/UI/ScrollIcon";
import Logo from "../components/UI/Logo";

const homePageElementsVariants = {
  hidden: {
    opacity: 0,
    x: -100,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
      delay: 1.2,
      staggerChildren: 0.1,
      when: "beforeChildren",
    },
  },
};

const homePageElementVariants = {
  hidden: {
    opacity: 0,
    x: -100,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
};

const sectionElementsVariants = {
  hidden: {
    opacity: 0,
    x: -100,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.2,
      type: "spring",
      bounce: 0.5,
      duration: 1,
    },
  },
};

export default function HomePage() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { theme } = useThemeStore();
  const [aboutCategory, setAboutCategory] = useState("aboutme");

  return (
    <>
      <Navbar />
      <MobileNavbar />
      <HeroBackground id="home">
        <div className="flex flex-row max-w-[1200px] justify-center items-center w-full px-6 xl:px-0 lg:justify-between">
          <motion.div
            className="flex flex-col items-center gap-6 max-w-[570px] lg:items-start"
            variants={homePageElementsVariants}
            animate="visible"
            initial="hidden"
          >
            <motion.div
              className="flex flex-row items-center gap-2"
              variants={homePageElementVariants}
            >
              <div
                className="w-[1px] h-3"
                style={{ backgroundColor: theme.fontSecondaryColor }}
              ></div>
              <h2
                className="text-2xl font-bold"
                style={{ color: theme.fontPrimaryColor }}
              >
                Thomas.B -{" "}
                <span style={{ color: theme.fontSecondaryColor }}>
                  Portfolio
                </span>
              </h2>
            </motion.div>
            <motion.p
              className="text-sm text-center lg:text-start"
              style={{ color: theme.fontPrimaryColor }}
              variants={homePageElementVariants}
            >
              {t("home.description")}
            </motion.p>
            <motion.div
              className="flex flex-row flex-wrap items-center gap-2 justify-center lg:justify-start"
              variants={homePageElementVariants}
            >
              {config.skills.map((skill, index) => (
                <Badge key={index} infos={skill} />
              ))}
            </motion.div>
          </motion.div>
          <motion.div
            className="hidden w-10 h-10 p-2 items-center justify-center lg:flex"
            style={{ backgroundColor: theme.fontSecondaryColor }}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            whileHover={{ scale: 1.1 }}
            transition={{
              opacity: { delay: 1.6 },
              x: { delay: 1.6 },
              duration: 0.3,
              type: "spring",
              bounce: 0.5,
            }}
          >
            <Logo color={theme.fontPrimaryColor} />
          </motion.div>
        </div>
        <motion.div
          className="absolute bottom-6 left-1/2 transform -translate-x-1/2 cursor-pointer"
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 1,
            delay: 2.5,
            type: "spring",
            bounce: 0.5,
          }}
          onClick={() => {
            window.scrollTo({
              top: window.innerHeight,
              behavior: "smooth",
            });
          }}
        >
          <ScrollIcon theme={theme} />
        </motion.div>
      </HeroBackground>
      <Section
        className="z-0 relative bg-thomasBlueGray overflow-hidden"
        id="about"
      >
        <motion.div
          className="max-w-[1200px] mx-auto py-20 pb-0 px-6 xl:px-0"
          variants={sectionElementsVariants}
        >
          <CategoryInfos
            title={t("about.title")}
            desc={t("about.description")}
            theme="dark"
          />
          <div className="flex flex-col gap-10 mt-6">
            <div className="flex flex-row gap-4 items-center max-w-full overflow-x-auto text-white text-sm webkit__scrollbar__none">
              <AboutButton
                title={t("about.sections.aboutme.title")}
                onClick={() => setAboutCategory("aboutme")}
                borderColor="#F4C200"
              />
              <AboutButton
                title={t("about.sections.professional.title")}
                onClick={() => setAboutCategory("professional")}
                borderColor="#5FC2BA"
              />
              <AboutButton
                title={t("about.sections.projects.title")}
                onClick={() => setAboutCategory("projects")}
                borderColor="#FFFFFF"
              />
            </div>
            <div className="flex flex-col justify-between gap-4 lg:flex-row">
              {aboutCategory === "aboutme" && (
                <AboutContent
                  title={t("about.sections.aboutme.title")}
                  content={t("about.sections.aboutme.content")}
                />
              )}
              {aboutCategory === "professional" && (
                <AboutContent
                  title={t("about.sections.professional.title")}
                  content={t("about.sections.professional.content")}
                />
              )}
              {aboutCategory === "projects" && (
                <AboutContent
                  title={t("about.sections.projects.title")}
                  content={t("about.sections.projects.content")}
                />
              )}
            </div>
          </div>
        </motion.div>
        <div className="z-[-1] absolute top-0 w-full h-72 flex items-center justify-center">
          <div className="w-[200%] h-full relative">
            <motion.div
              className="w-[200%] flex flex-row items-end h-full justify-around absolute left-0 gap-20"
              animate={{ left: "-100%" }}
              transition={{
                duration: 10,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "linear",
              }}
            >
              <motion.p
                className="font-extrabold uppercase strokeText whitespace-nowrap"
                style={{ fontSize: "20rem" }}
              >
                {t("about.title")}
              </motion.p>
              <motion.p
                className="font-extrabold uppercase strokeText whitespace-nowrap"
                style={{ fontSize: "20rem" }}
              >
                {t("about.title")}
              </motion.p>
              <motion.p
                className="font-extrabold uppercase strokeText whitespace-nowrap"
                style={{ fontSize: "20rem" }}
              >
                {t("about.title")}
              </motion.p>
              <motion.p
                className="font-extrabold uppercase strokeText whitespace-nowrap"
                style={{ fontSize: "20rem" }}
              >
                {t("about.title")}
              </motion.p>
            </motion.div>
          </div>
        </div>
        <GradientTransition color="#00464B" />
      </Section>
      <Section className="relative bg-thomasBlueGray" id="creations">
        <motion.div
          className="max-w-[1200px] mx-auto py-20 px-6 xl:px-0"
          variants={sectionElementsVariants}
        >
          <CategoryInfos
            title={t("creations.title")}
            desc={t("creations.description")}
            theme="dark"
          />
          <div className="mt-16 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
            <CreationCard
              title={t("creations.cards.logofolio")}
              desc={t("creations.creationsDate")}
              borderColor="#FFEDA9"
              img="/creations/logofolio.svg"
              link="https://www.behance.net/gallery/150271203/LOGOFOLIO-2022"
            />
            <CreationCard
              title={t("creations.cards.poster")}
              desc={t("creations.creationsDate")}
              borderColor="#7EE4CD"
              img="/creations/poster.svg"
              link="https://www.behance.net/gallery/150271573/POSTERS"
            />
            <CreationCard
              title={t("creations.cards.textile")}
              desc={t("creations.creationsDate")}
              borderColor="#96A4FF"
              img="/creations/textile.svg"
              link="https://www.behance.net/gallery/151344439/Textile-2022"
            />
            <CreationCard
              title={t("creations.cards.banner")}
              desc={t("creations.creationsDate")}
              borderColor="#D3D9FF"
              img="/creations/banner.svg"
              link="https://www.behance.net/gallery/150273579/Banners-2022"
            />
          </div>
        </motion.div>
        <ColorBars />
      </Section>
      <Section
        className="z-0 relative bg-thomasSapinGreen overflow-hidden"
        id="projects"
      >
        <motion.div
          className="max-w-[1200px] mx-auto py-20 px-6 xl:px-0"
          variants={sectionElementsVariants}
        >
          <CategoryInfos
            title={t("projects.title")}
            desc={t("projects.description")}
            theme="dark"
          />
          <div className="mt-16 grid grid-cols-1 gap-6 md:grid-cols-2">
            {config.projects.map((project, index) => (
              <ProjectCard
                key={index}
                name={project.name}
                type={project.type[language]}
                img={project.img}
                link={project.link}
              />
            ))}
          </div>
        </motion.div>
        <div className="absolute top-0 right-0 translate-x-96 opacity-20 h-[750px] z-[-1]">
          <Logo color="#FFFFFF" />
        </div>
        <GradientTransition color="#16192C" />
      </Section>
      <Section className="z-0 relative bg-white overflow-hidden" id="contact">
        <ContactForm variants={sectionElementsVariants} />
        <div className="flex flex-row gap-2 mb-3 justify-center items-center flex-wrap mx-auto px-6 md:px-0">
          {config.socials.map((social, index) => (
            <a
              key={index}
              href={social.link}
              className="w-10 h-10 p-2 bg-thomasSapinGreen flex justify-center items-center"
              target="_blank"
              rel="noreferrer"
            >
              <img src={social.img} alt={social.name} className="h-4" />
            </a>
          ))}
        </div>
        <p className="text-center text-thomasSapinGreen mb-10 text-sm px-6 sm:px-0">
          {t("contact.designBy")} <b>Thomas.b</b>
        </p>
        <div className="absolute top-0 left-0 -translate-x-96 opacity-20 h-full w-auto z-[-1]">
          <Logo color="#5FC2BA" />
        </div>
        <GradientTransition color="#5FC2BA" />
      </Section>
    </>
  );
}
