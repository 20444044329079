import { motion } from "framer-motion";

export default function AboutContent({ title, content }) {
  return (
    <>
      <motion.h1
        className="w-full text-3xl font-bold text-white lg:text-5xl lg:max-w-[25%]"
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{
          duration: 0.3,
          delay: 0.2,
          type: "spring",
          bounce: 0.5,
        }}
      >
        {title}
      </motion.h1>
      <motion.div
        className="w-full text-white text-xs lg:max-w-[67%]"
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{
          duration: 0.3,
          delay: 0.2,
          type: "spring",
          bounce: 0.5,
        }}
      >
        {content}
      </motion.div>
    </>
  );
}
