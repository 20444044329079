import { motion } from "framer-motion";

export default function Badge({ infos }) {
  return (
    <motion.div
      className="px-4 flex flex-row items-center gap-2 py-1 border rounded text-xs"
      style={{
        color: infos.fontColor,
        backgroundColor: infos.bgColor,
        borderColor: infos.borderColor,
      }}
      whileHover={{ scale: 1.05 }}
    >
      <div className="w-3 h-3">
        <img
          src={infos.img}
          alt={infos.name}
          className="h-full pointer-events-none"
        />
      </div>
      {infos.name}
    </motion.div>
  );
}
