export default function GradientTransition({ color }) {
  return (
    <div
      className="absolute top-0 left-0 w-full h-10"
      style={{
        background: `linear-gradient(180deg, ${color} 0%, rgba(255, 255, 255, 0) 100%)`,
        opacity: 0.3,
      }}
    ></div>
  );
}
